import User from "@models/old/User";
import axiosInstance from "@services/api/config";
import { AccessType } from "@services/authentication/AuthenticationContext";
import LogService from "@services/log/service";

const UserAPI = {
  signIn: (token: string, accessType: AccessType): Promise<User> => {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${token}`,
    };
    if (accessType === "buyer") {
      headers.context = "buyer";
    }
    return axiosInstance
      .get(`/sign-in`, {
        headers,
      })
      .then((response) => response.data)
      .catch(LogService.error);
  },
};

export default UserAPI;

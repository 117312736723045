import React, { useMemo, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { z } from "zod";

import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";

interface FormValues {
  brandId: string;
  brandName: string;
  email?: string;
}

function NewBrandForm() {
  const { t } = useTranslation();
  const [sendEmailInvitation, setSendEmailInvitation] = useState(false);
  const { data: brands } = GetAllBrandsEndpoint.useHook();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      brandId: "",
      brandName: "",
      email: "",
    },
  });

  const values = watch();

  // check if brand name and brand id are empty
  // to avoid displaying the "brand name missing" error after the brand is created
  const noBrandNameError = useMemo(
    () => !values.brandName && !values.brandId,
    [values.brandName, values.brandId],
  );

  // handle form submission
  const onSubmitForm = (data: FormValues) => {
    if (sendEmailInvitation) {
      console.log("Sending invitation:", data); // TODO: remove after endpoint implementation
    } else {
      console.log("Adding brand:", data); // TODO: remove after endpoint implementation
    }
  };

  // render brand name field
  const renderBrandNameField = () => (
    <div className="mt-5 relative">
      <label className="font-medium" htmlFor="brandName">
        {t("BuyerAccount.crm.create-brand.brand-label")}
      </label>
      <div>
        <Controller
          name="brandId"
          rules={{
            required: t("BuyerAccount.crm.error.brand-select-or-enter"),
          }}
          control={control}
          render={({ field: { onChange } }) => (
            <CreatableSelect
              isClearable
              name="brandId"
              className="mt-1"
              options={brands?.map((brand) => ({
                label: brand.name,
                value: brand.id,
              }))}
              placeholder={t("BuyerAccount.crm.create-brand.brand-placeholder")}
              value={
                values.brandName
                  ? { label: values.brandName, value: values.brandId }
                  : null
              }
              onCreateOption={(brandName) => {
                onChange("");
                setValue("brandName", brandName);
                setSendEmailInvitation(true);
              }}
              onChange={(o) => {
                onChange(o?.value || "");
                setValue("brandName", o ? o.label : "");
                setSendEmailInvitation(false);
              }}
              aria-label={t("BuyerAccount.crm.create-brand.brand-label")}
            />
          )}
        />
        {formErrors.brandId && noBrandNameError && (
          <span className="text-primaryRed text-sm">
            {formErrors.brandId.message}
          </span>
        )}
      </div>
    </div>
  );

  // render email field
  const renderEmailField = () => (
    <div className="mt-4">
      <label className="font-medium" htmlFor="email">
        {t("BuyerAccount.crm.create-brand.email-label")}
      </label>
      <div>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            validate: {
              invalid: (value) => z.string().email().safeParse(value).success,
            },
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <TextField
              className="mt-1 rounded-md"
              id="email"
              placeholder={t("BuyerAccount.crm.create-brand.email-placeholder")}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              aria-invalid={formErrors.email ? "true" : "false"}
              hasError={!!error}
              grayCloseIcon // to match the gray color of the close icon in the CreatableSelect
              aria-label={t("BuyerAccount.crm.create-brand.email-label")}
            />
          )}
        />
        {formErrors.email && formErrors.email.type === "required" && (
          <p className="text-sm italic text-primaryRed">
            {t("Common.form.this-is-required")}
          </p>
        )}
        {formErrors.email && formErrors.email.type === "invalid" && (
          <p className="text-sm italic text-primaryRed">
            {t("Common.form.this-is-not-valid-email")}
          </p>
        )}
      </div>
    </div>
  );

  const renderSubmitButton = () => (
    <Button
      className="whitespace-nowrap text-primaryElectricBlue hover:underline mt-4 flex w-full"
      theme="SECONDARY"
      justify="center"
      type="submit"
      aria-label={
        sendEmailInvitation
          ? t("BuyerAccount.crm.create-brand.send-invitation")
          : t("BuyerAccount.crm.create-brand.add-brand")
      }
    >
      {sendEmailInvitation
        ? t("BuyerAccount.crm.create-brand.send-invitation")
        : t("BuyerAccount.crm.create-brand.add-brand")}
    </Button>
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div>
        {!sendEmailInvitation && (
          <div>
            <p>{t("BuyerAccount.crm.create-brand.fill-form")}</p>
          </div>
        )}
        {sendEmailInvitation && (
          <div className="mt-2 mb-2 w-85">
            <Trans
              i18nKey={t("BuyerAccount.crm.create-brand.invite-brand-message")}
            />
          </div>
        )}
        {renderBrandNameField()}
        {sendEmailInvitation && (
          <>
            {renderEmailField()}
            <div className="italic text-sm text-gray-400 mt-8">
              <Trans
                i18nKey={t("BuyerAccount.crm.create-brand.invite-brand-value")}
              />
            </div>
          </>
        )}
      </div>
      {renderSubmitButton()}
    </form>
  );
}

export default NewBrandForm;

import React from "react";

import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { BlueRow } from "@components/data-display/BlueRow";
import { ShowroomSeason } from "@models/types/enums";
import { GetOrderDeadline } from "@services/api/order-deadline/get-order-deadline";

interface OpeningDays {
  day: Date;
  keyAccountsOpeningHour: Date | null;
  keyAccountsClosingHour: Date | null;
  customOpeningHour: Date | null;
  customClosingHour: Date | null;
}
interface Showroom {
  id: string;
  name: string;
  season: ShowroomSeason;
  year: number;
  openingDays: OpeningDays[];
  formattedAddress: string;
}

interface BuyerBrand {
  id: string;
  name: string;
  showrooms: Showroom[];
}

interface BuyerBrandProps {
  buyerBrand: BuyerBrand;
}

function BuyerBrandsRowsDisplay({ buyerBrand }: BuyerBrandProps) {
  const { t } = useTranslation();
  // fetch order deadline
  // create a Set of unique season-year combinations
  const uniqueSeasonYearCombos = new Set(
    buyerBrand.showrooms.map(
      (showroom) => `${showroom.season}-${showroom.year}`,
    ),
  );

  // Create the query array based on unique season-year combinations
  const queryArray = Array.from(uniqueSeasonYearCombos).map((combo) => {
    const [season, year] = combo.split("-");
    return {
      queryKey: GetOrderDeadline.getQueryKeys({
        brandId: buyerBrand.id,
        season,
        year: parseInt(year, 10),
      }),
      queryFn: () =>
        GetOrderDeadline.call({
          brandId: buyerBrand.id,
          season,
          year: parseInt(year, 10),
        }),
    };
  });

  // fetch data using useQueries
  const { data: orderDeadlines } = useQueries({
    queries: queryArray,
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  return (
    <>
      <div className="mt-7 mb-2 grid grid-cols-4">
        <p className="font-light text-left text-sm pl-2 col-span-1">
          {t("BuyerAccount.crm.secondary-table-header.showroom-name")}
        </p>
        <p className="font-light text-left text-sm pl-4 col-span-1">
          {" "}
          {t("BuyerAccount.crm.secondary-table-header.dates")}
        </p>
        <p className="font-light text-left text-sm pl-4 col-span-1">
          {t("BuyerAccount.crm.secondary-table-header.location")}
        </p>
        <p className="font-light text-left text-sm pl-4 col-span-1">
          {t("BuyerAccount.crm.secondary-table-header.order-deadline")}
        </p>
      </div>
      <hr />
      <div className="mt-5">
        {buyerBrand.showrooms.map((showroom) => {
          const matchingDeadline = orderDeadlines?.find(
            (deadline) =>
              deadline.season.toLowerCase() === showroom.season.toLowerCase() &&
              deadline.year === showroom.year &&
              deadline.brandId === buyerBrand.id,
          );

          return (
            <BlueRow key={showroom.id} colsClass="grid-cols-4">
              <div
                key={showroom.id}
                className="py-3 px-4 font-light text-left col-span-1"
              >
                <p className="text-sm col-span-1">{showroom.name}</p>
              </div>
              <div className="text-sm py-3 px-4 font-light text-left col-span-1">
                {format(showroom.openingDays[0].day, "MMM d yyyy")} -{" "}
                {format(
                  new Date(
                    showroom.openingDays[showroom.openingDays.length - 1].day,
                  ),
                  "MMM d yyyy",
                )}
              </div>
              <div className="py-3 px-4 font-light text-left col-span-1">
                <p className="text-sm col-span-1">
                  {showroom.formattedAddress}
                </p>
              </div>
              <div className="py-3 px-4 font-light text-left col-span-1">
                <p className="text-sm col-span-1">
                  {matchingDeadline?.deadline
                    ? format(new Date(matchingDeadline.deadline), "MMM d, yyyy")
                    : "-"}
                </p>
              </div>
            </BlueRow>
          );
        })}
      </div>
    </>
  );
}

export default BuyerBrandsRowsDisplay;
